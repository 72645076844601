<template>
  <router-link to="/categories">
    <div class="page">
        <video :src="require(`@/assets/video/GGDB_DREAMWALL_LOOP.mp4`)" type="video/mp4" loop autoplay></video>
    </div>
  </router-link>
</template>

<script>

  const appVideo = {
    name: "Video",
    data() {
      return {
        someproperty: ""
      }

    } 
  };
  export default appVideo;
</script>

<style lang="scss" scoped>
.page {
  height: 100%;
  width: 100%;
  display: flex;
  background-color: black;
}
video {
  height: 100%;
  width: 100%;
  background-color: black;
  object-fit: contain;
}
</style>